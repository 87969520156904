<template>
	<div class="background">
		<div class="login_box">
			<div>
				<Input prefix="ios-contact" v-model="account" size="large" placeholder="请输入用户名" style="width: 300px" />
			</div>
			<br />
			<div>
				<Input prefix="md-lock" type="password" v-model="password" size="large" placeholder="请输入密码" style="width: 300px" />
			</div>
			<br />
			<Button :loading="login_loading" type="primary" size="large" style="width: 300px" @click="login">登       录</Button>
		</div>
	</div>
</template>
<script>
	export default {
		data () {
			return {
				account: "",
				password: "",
				login_loading: false
			}
		},
		methods:{
			login(){
				this.login_loading = true;
                this.post("/login", {
                   account: this.account,
                   password: this.password,
                }).then(( res ) => {
					this.$store.commit("user/saveInfo", res.data.data)
                    // this.router.push("/index");
                    this.login_loading = false;
                }).catch((e) => {
                    console.log(e);
                    this.$message.error(e.msg)
                    this.login_loading = false;
                })
			}
		}
	}
</script>
<style>
	.background{
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: url(../../public/imgs/login_background_01.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.login_box{
        text-align: center;
		width: 400px;
		margin: 10% auto;
		background: #e5e9ef;
		background: rgba(229, 233, 239, 0.5);
		padding: 50px 30px 50px 30px;
		border-radius: 5px;
	}
</style>
